<template>
  <b-card class="card-wrapper">
    <b-container v-if="project.objectID">
      <b-row>
        <b-col sm="10">
          <b-form inline @submit.prevent>
            <b-form-input
              type="search"
              size="sm"
              v-model="searchText"
              :placeholder="'Search logs...'"
            />
            <b-form-select
              :options="endpointOptions"
              size="sm"
              class="ml-1"
              v-model="filters.endpoint"
            />
            <b-form-select
              :options="levels"
              size="sm"
              class="ml-1"
              v-model="filters.level"
            />
            <b-dropdown
              id="filter-range"
              variant="outline-secondary"
              size="sm"
              class="ml-1 dropdown-filter"
            >
              <template #button-content>
                <b-icon-calendar-range class="mr-1"></b-icon-calendar-range>
              </template>
              <b-dropdown-group header="Date Range Starting:">
                <b-dropdown-form>
                  <b-form-datepicker
                    id="filter-start-date"
                    class="filterPicker"
                    v-model="filters.start_date"
                    size="sm"
                    locale="en"
                  ></b-form-datepicker>
                  <b-form-timepicker
                    id="filter-start-time"
                    class="filterPicker"
                    v-model="filters.start_time"
                    size="sm"
                    locale="en"
                  ></b-form-timepicker>
                </b-dropdown-form>
              </b-dropdown-group>
              <b-dropdown-group header="Date Range Ending:">
                <b-dropdown-form>
                  <b-form-datepicker
                    id="filter-end-date"
                    class="filterPicker"
                    v-model="filters.end_date"
                    size="sm"
                    locale="en"
                  ></b-form-datepicker>
                  <b-form-timepicker
                    id="filter-end-time"
                    class="filterPicker"
                    v-model="filters.end_time"
                    size="sm"
                    locale="en"
                  ></b-form-timepicker>
                </b-dropdown-form>
              </b-dropdown-group>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item-button @click="clear('date-range')"
                ><i class="far fa-times-circle"></i> Clear
                All</b-dropdown-item-button
              >
            </b-dropdown>
          </b-form>
        </b-col>
        <b-col sm="2" class="text-right">
          <b-button
            variant="danger"
            size="sm"
            :disabled="selected.length === 0"
            @click="deleteSelected"
          >
            <i class="far fa-trash-alt"></i> Delete
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            hover
            striped
            responsive
            :fields="fields"
            :items="logList"
            :per-page="perPage"
            :current-page="currentPage"
            class="mt-3"
          >
            <template #cell(select)="data">
              <b-form-checkbox v-model="selected" :value="data.item.key">
              </b-form-checkbox>
            </template>
            <template #cell(date)="data">
              {{ formatDate(data.item.date) }}
            </template>
            <template #cell(level)="data">
              <span
                :class="{
                  'text-info': data.item.level === 'info',
                  'text-warning': data.item.level === 'warn',
                  'text-danger': data.item.level === 'error',
                }"
                >{{ data.item.level }}</span
              >
            </template>
            <template #cell(actions)="data">
              <b-button variant="danger" size="sm" @click="deleteLog(data.item)"
                ><i class="far fa-trash-alt"></i
              ></b-button>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="logList.length"
            :per-page="perPage"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else>
      <b-alert variant="info" show
        ><div class="alert-message">
          <i class="fas fa-exclamation-circle"></i> Please select a project.
        </div></b-alert
      >
    </b-container>
  </b-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import { DateTime } from "luxon";

export default {
  name: "Logs",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {
      project: {},
      apis: [],
      logs: [],
      searchText: "",
      filters: {
        endpoint: null,
        level: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
      },
      fields: [
        {
          key: "select",
          label: "",
          sortable: true,
        },
        {
          key: "date",
          label: "Date",
          sortable: true,
        },
        {
          key: "level",
          label: "Level",
          sortable: true,
        },
        {
          key: "message",
          label: "Message",
          sortable: true,
        },
        {
          key: "endpoint",
          label: "Endpoint",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      levels: [
        {
          text: "All Levels",
          value: null,
        },
        {
          text: "Info",
          value: "info",
        },
        {
          text: "Warn",
          value: "warn",
        },
        {
          text: "Error",
          value: "error",
        },
      ],
      currentPage: 1,
      perPage: 20,
      selected: [],
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userDisplayName", "userAvatar"]),
    userUid() {
      return this.userProfile.uid;
    },
    logList() {
      return this.logs
        .map((log) => {
          return { key: log[".key"], ...log };
        })
        .filter((log) => {
          // Filter by endpoint
          if (
            this.filters.endpoint !== null &&
            this.filters.endpoint !== log.endpoint
          ) {
            return false;
          }
          // Filter by level
          if (this.filters.level !== null && this.filters.level !== log.level) {
            return false;
          }
          // Filter by date range
          if (log.date !== null) {
            let logDate = new Date(log.date);
            if (this.filters.start_date !== null) {
              let rangeStart;
              if (this.filters.start_time !== null) {
                rangeStart = new Date(
                  this.filters.start_date + " " + this.filters.start_time
                );
              } else {
                rangeStart = new Date(this.filters.start_date + " 23:59:00");
              }
              if (logDate.getTime() < rangeStart.getTime()) {
                return false;
              }
            }
            if (this.filters.end_date !== null) {
              let rangeEnd;
              if (this.filters.end_time !== null) {
                rangeEnd = new Date(
                  this.filters.end_date + " " + this.filters.end_time
                );
              } else {
                rangeEnd = new Date(this.filters.end_date + " 23:59:00");
              }
              if (logDate.getTime() > rangeEnd.getTime()) {
                return false;
              }
            }
          }
          // Filter by search string
          if (this.searchText.length > 1) {
            const searchText = this.searchText.toLowerCase();
            const level = log.level.toLowerCase();
            const message = log.message.toLowerCase();
            const endpoint = log.endpoint.toLowerCase();
            if (
              !level.includes(searchText) &&
              !message.includes(searchText) &&
              !endpoint.includes(searchText)
            ) {
              return false;
            }
          }
          return true;
        })
        .sort((a, b) => {
          return b.date - a.date;
        });
    },
    endpointOptions() {
      return [
        {
          text: "All Endpoints",
          value: null,
        },
        ...this.apis.map((api) => {
          return {
            text: api.name + " (" + api.endpoint + ")",
            value: api.endpoint,
          };
        }),
      ];
    },
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        this.bindObject("selected", uid, "project");
      },
    },
    project: {
      immediate: true,
      handler(project) {
        if (project.objectID) {
          this.bindObject("apis", project.objectID, "apis");
          this.bindObject("logs", project.objectID, "logs", 500, true);
        }
      },
    },
  },
  methods: {
    deleteLog(log) {
      const key = log.key;

      this.deleteObject("logs/" + this.project.objectID, key)
        .then(() => {
          this.notyf.success("Log deleted successfully.");
        })
        .catch((error) => {
          this.notyf.error("An error occurred deleting the log.");
          console.error("Log delete failed", key, error);
        });
    },
    deleteSelected() {
      if (this.selected.length > 0) {
        const promises = [];
        for (const key of this.selected) {
          promises.push(
            this.deleteObject("logs/" + this.project.objectID, key)
          );
        }
        Promise.all(promises)
          .then(() => {
            this.notyf.success("Logs deleted successfully.");
          })
          .catch((error) => {
            this.notyf.error("An error occurred deleting the logs.");
            console.error("Log delete failed", error);
          });
      }
    },
    clear(type) {
      switch (type) {
        case "date-range":
          this.filters.start_date = null;
          this.filters.start_time = null;
          this.filters.end_date = null;
          this.filters.end_time = null;
          break;
        default:
          this.filters.endpoint = null;
          this.filters.level = null;
          this.filters.start_date = null;
          this.filters.start_time = null;
          this.filters.end_date = null;
          this.filters.end_time = null;
      }
    },
    formatDate(date) {
      const dt = DateTime.fromMillis(date);
      return dt.toLocaleString(DateTime.DATETIME_FULL);
    },
  },
  mounted() {
    this.$root.$on("bv::dropdown::show", (bvEvent) => {
      if (
        bvEvent.componentId === "filter-start-date" ||
        bvEvent.componentId === "filter-start-time" ||
        bvEvent.componentId === "filter-end-date" ||
        bvEvent.componentId === "filter-end-time"
      ) {
        this.isDatePickerVisible = true;
      }
    });
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      if (
        bvEvent.componentId === "filter-start-date" ||
        bvEvent.componentId === "filter-start-time" ||
        bvEvent.componentId === "filter-end-date" ||
        bvEvent.componentId === "filter-end-time"
      ) {
        setTimeout(() => {
          this.isDatePickerVisible = false;
        }, 500);
      }
      if (bvEvent.componentId === "filter-range" && this.isDatePickerVisible) {
        bvEvent.preventDefault();
      }
    });
  },
};
</script>

<style lang="scss">
body[data-theme="light"] {
  .dropdown-filter > .btn {
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
  }
}
body[data-theme="dark"] {
  .dropdown-filter > .btn {
    color: #bfc1c6;
    border: 1px solid #7f838e;
    border-radius: 0.2rem;
  }
}
</style>

<style lang="scss" scoped>
.card-wrapper {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.filterBtn {
  width: 120px;
  text-align: left;
}

.filterPicker {
  width: 250px;
}

.filterSearch {
  width: 200px;
}
</style>
